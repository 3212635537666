<template>
  <div v-loading.fullscreen.lock='loading' element-loading-text="拼命加载中" element-loading-background="rgba(255, 255, 255, 0.5)">
     <el-select v-model="pageInfo.profileId" style="margin-bottom:15px;width:150px;margin-right:10px" placeholder="请选择店铺">
        <el-option-group
          v-for="group in tress"
          :key="group.id"
          :label="group.name">
          <el-option
              v-for="item in group.marketPlaceTree"
              :key="item.profileId"
              :label="group.name+'-'+item.name"
              :value="item.profileId"
              @click.native='getID(item.profileId,item.currency)'>{{ item.name }}
          </el-option>
      </el-option-group>
    </el-select>
    <TimeQuantum style="margin-right:10px;" @selectFinish='getTimeSection' @sectionFinish='getDate' section='近7天'></TimeQuantum>
    <el-select clearable v-model="pageInfo.portfoliosId" style="width:150px;margin-right:10px" filterable placeholder="请选择组合" @change="find">
        <el-option
        v-for="item in portfoliosList"
        :key="item.id"
        :label="item.value"
        :value="item.id">
        </el-option>
    </el-select>
    <el-select clearable v-model="pageInfo.state" style="width:150px;margin-right:10px" filterable placeholder="选择广告状态" @change="find">
        <el-option
        v-for="item in adCampaignsStateList"
        :key="item.id"
        :label="item.value"
        :value="item.id">
        </el-option>
    </el-select>
    <el-select clearable v-model="pageInfo.targetingType" style="width:150px;margin-right:10px" filterable placeholder="所有投放" @change="find">
        <el-option
        v-for="item in adCampaignsTargetingTypeList"
        :key="item.id"
        :label="item.value"
        :value="item.id">
        </el-option>
    </el-select>
    <el-select clearable v-model="pageInfo.strategy" style="width:150px;margin-right:10px" filterable placeholder="竞价策略" @change="find">
        <el-option
        v-for="item in adCampaignsStrategyTypeList"
        :key="item.id"
        :label="item.value"
        :value="item.id">
        </el-option>
    </el-select>
    <el-select clearable v-model="pageInfo.isFavorite" style="width:150px;margin-right:10px" filterable placeholder="关注状态" @change="find">
        <el-option
        v-for="item in favoriteTypeList"
        :key="item.id"
        :label="item.value"
        :value="item.id">
        </el-option>
    </el-select>
    <el-input clearable v-model="pageInfo.keyword" style="width:150px;margin-right:10px" placeholder="输入广告名称" @keyup.enter.native = "find"></el-input>
    <el-button type="primary" @click="find">立即查询</el-button>
    <el-button type="primary" style="margin-right:10px" @click="addADactive">增加广告活动</el-button>
    <el-dropdown trigger='click' style="margin-right:10px">
        <el-button type="primary">
            批量操作<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
            v-for='item in operateTypeList'
            :key="item.id"
            @click.native="batchHandle(item.id,item.values)">
            {{item.values}}
            </el-dropdown-item>
        </el-dropdown-menu>
    </el-dropdown>
    <el-button type="primary" @click="addGroup">新增广告组合</el-button>
    <el-tooltip effect="dark" content="预算上限:使用每日预算上限,每日上限￥20,000.00" placement="top">
      <el-button type="primary" icon="el-icon-setting" @click="budgetSetting">每日预算设置</el-button>
    </el-tooltip>
    <tableDialog style="float:right" @setTableHead='setHead' :tableList='headList'></tableDialog>
    <el-table
    class="min"
    :data="tableData"
    style="width: 100%;margin-top:10px"
    @sort-change="sortTable"
    @selection-change="handleSelectionChange"
    :cell-style="{ fontSize: '13px', padding: '7px 0',textAlign:'center' }"
    :header-cell-style="{
        fontSize: '13px',
        padding: '9px 0',
        marginTop: '10px',
        textAlign:'center',
        background:'#EBEEF5'
    }">
        <el-table-column
        type="selection"
        :selectable="selectable"
        width="45">
        </el-table-column>
        <el-table-column
            prop="name"
            label="广告活动"
            width="250"
            fixed="left">
            <template slot-scope="scope">
                <p style="text-align:left;display:flex;align-items:center">
                    <span @click="focusChange(scope.row.id,scope.row.focusOn)" :class="scope.row.focusOn==0?'el-icon-star-off':'el-icon-star-on'" style="font-size:17px;margin-right:10px"></span>
                    <span>{{scope.row.name}}</span>
                </p>
            </template>
        </el-table-column>
        <el-table-column
            prop="prop"
            label="启停/分析/广告位/更改"
            width="170"
            fixed="left">
            <template slot-scope="scope">
              <div style="display:flex;justify-content:space-around;align-items:center;padding:0 20px">
                <el-switch 
                v-if="scope.row.state!='archived'"
                v-model="scope.row.state"
                active-color="#13ce66" 
                inactive-color="#C1C1C1"
                active-value="enabled"
                inactive-value="paused"
                @change="changeStatus(scope.row.id,scope.row.state,scope.$index)"
                ></el-switch>
                <el-link v-else-if="scope.row.state=='archived'" disabled class="el-icon-document" style="font-size:25px"></el-link>
                <el-tooltip effect="dark" placement="top" content="查看历史趋势">
                  <el-link class="iconfont icon-jingyingfenxi" style="font-size:25px" @click="getChart(scope.row)"></el-link>
                </el-tooltip>
                <!-- <el-link class="iconfont icon-dingdanliebiao" style="font-size:20px"></el-link> -->
                <el-link v-if="scope.row.state!=='archived'" class="el-icon-setting" style="font-size:23px" @click="updateActive(scope.row.id,scope.row.campaignId,scope.row.targetingType)"></el-link>
              </div>
            </template>
        </el-table-column>
        <el-table-column
            :prop='item.prop'
            header-align="center"
            align="center"
            label="label" 
            :sortable="item.sortable?'custom':false"
            v-for="item in tableHead"
            :key='item.name'
            :min-width="item.width">
            <template slot="header">
                <span v-if="item.name=='竞价策略'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:200px;max-width:350px">
                            <p>广告点击付费的方式。</p>
                            <p>1、仅降低：当您的广告不太可能带来销售时，我们将实时降低您的竞价。</p>
                            <p>2、提高和降低：当您的广告很有可能带来销售时，我们将实时提高您的竞价（最高可达 100%），并在您的广告不太可能带来销售时降低您的竞价。</p>
                            <p>3、固定竞价：我们将使用您的确切竞价和您设置的任何手动调整，而不会根据售出可能性对您的竞价进行更改。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='起止日期'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:200px;max-width:350px">
                            <p>广告活动开始和结束的日期。</p>
                            <p>1、您可以设置未来的某个日期作为开始日期，以便在该日期启动广告活动。</p>
                            <p>2、为确保广告始终投放而不错过展示或点击机会，可选择不选择保持“无结束日期”。</p>
                            <p>3、您可以在广告活动开展期间随时延长、缩短或结束广告活动。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='广告组合'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:200px;max-width:200px">
                            <p>是一组可以设置预算上限的广告活动。组合使您可以对广告活动进行分组和组织。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='定时策略'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:420px">
                            <p>广告对象定时策略绑定情况。</p>
                            <p>悬停策略类型名称上，可以查看策略的名称、状态、生效状态和应用策略状态。</p>
                            <p>点击策略类型名称，可以查看策略的详情、启停应用状态和修改分时计算基准值。</p>
                            <p>策略类型名称如果是<span style="color:#BF8350">灰色字体，表示策略当前处于不被执行状态</span>（策略未生效、策略被暂停、对象暂停应用了策略）。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='每日预算'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:420px">
                            <p>您愿意每天为该广告活动支出的金额。</p>
                            <p>如果广告活动支出低于您的每日预算，则这笔差额可用于增加该日历月的其他日子的每日预算，最多可增加 10%。</p>
                            <p>预算值下方出现红色的“<span style="color:#BF8350">超+时间</span>”表示：当前已超今日预算，展示的时间就是超过预算的时间点（站点时间）。</p><br>
                            <p>特别说明：</p>
                            <p>1、超预算时间点，由于数据同步非实时，所以可能存在一定的延迟；</p>
                            <p>2、如果超预算后，调大了每日预算，那么下一个数据同步时间点，又会重新判断是否超预算，如果没有超过，则不再出现超预算的时间，如果还是超过，则更新超预算的时间；</p>
                            <p>3、点击预算值后面的趋势图标，<span style="color:#BF8350">可以查看近7日曝光量、点击量、广告订单量、广告销售额、广告花费、每日预算6项指标的分时数据</span>；</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='曝光量'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:220px">
                            <p>广告投放给买家的展示总数。</p><br>
                            <p>没有曝光量就无法带来点击及购买转化，因此整体预算内，曝光量越高越好。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='点击量'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>点击量：广告被点击的总次数。</p>
                            <p>点击率：每一次曝光被点击的概率。</p><br>
                            <p>计算规则：广告点击率 = 点击量 / 曝光量 * 100%；</p><br>
                            <p>亚马逊广告点击率一般在0.6%-2.5%左右。</p>
                            <p>点击率高：说明选词匹配度越高，同时您的商品标题、主图、价格、评论数量，较好的吸引用户点击。</p>
                            <p>点击率低：建议对选词，商品标题、主图、价格、评论数量进行优化。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='点击率'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>点击量：广告被点击的总次数。</p>
                            <p>点击率：每一次曝光被点击的概率。</p><br>
                            <p>计算规则：广告点击率 = 点击量 / 曝光量 * 100%；</p><br>
                            <p>亚马逊广告点击率一般在0.6%-2.5%左右。</p>
                            <p>点击率高：说明选词匹配度越高，同时您的商品标题、主图、价格、评论数量，较好的吸引用户点击。</p>
                            <p>点击率低：建议对选词，商品标题、主图、价格、评论数量进行优化。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='花费'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>广告被点击，所产生的总费用，相当于您的广告总成本 。</p><br>
                            <p>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</p><br>
                            <p>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；</p>
                            <p>3、计算规则：广告花费 = CPC竞价 * 被点击次数；</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='CPC均价'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>CPC点击的平均价格。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='订单数'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                            <p>通过广告带来的订单量。</p><br>
                            <p>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</p>
                            <p>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='CVR'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                            <p>每一次点击带来订单的概率。</p><br>
                            <p>计算规则：CVR = 订单数 / 点击量 * 100%；</p>
                            <p>例如：CVR为10%，每100次点击，能带来10笔广告订单。</p><br>
                            <p>亚马逊广告，CVR一般为5-10%左右，</p>
                            <p>CVR的重要影响因素：商品图片、卖点、评论内容、促销活动，如果CVR低，建议您优化listing，根据商品的受众人群，提炼卖点、设计精美的场景图、善用Q&A，Review等。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='CPA'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                          <p>每一笔广告订单平均所需花费用</p><br>
                          <p>计算规则：CPA = 花费 / 订单数；</p><br>
                          <p>商品的新品期，CPA相对较高，</p>
                          <p>商品的稳定期，CPA逐渐降低。</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='销售额'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                            <p>通过广告带来的销售额。</p><br>
                            <p>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</p>
                            <p>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='ACoS'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                            <p>广告销售成本率，广告花费占广告销售额的比例。</p><br>
                            <p>计算规则：ACoS = 广告花费 / 广告销售额 * 100%；</p>
                            <p>例如，ACoS为90%，100美元的销售额，需要支付90%（90元）广告费。</p>
                            <p>ACoS是衡量广告效果的重要指标，一般来说越低越好，</p>
                            <p>25%是业内普遍的衡量标准，</p>
                            <p>但并不能用ACoS指标单一来衡量广告的效果。</p><br>
                            <p>在不同的细分市场、商品周期、用户周期，品牌定位，都影响着ACoS的设置目标，</p>
                            <p>例如，客户复购率高、忠诚度高、终身价值高，或是有品牌光环和生态的商品，</p>
                            <p>通过广告一次获客，客户可带来长期的购买回报，</p>
                            <p>因此这类商品ACoS高，反而有益于创造更多利润。</p><br>
                            <p>拓展阅读<a href="https://www.baidu.com/s?ie=UTF-8&wd=ACoS%E8%B6%8A%E4%BD%8E%E8%B6%8A%E5%A5%BD%E5%90%97%EF%BC%9F&tn=62095104_41_oem_dg" style="color:#13CE66">ACoS越低越好吗？</a></p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else-if="item.name=='ROAS'">
                    <span>{{item.name}}</span>
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                            <p>广告投入回报比，广告销售额与成本的倍数。</p><br>
                            <p>计算规则：RoAS = 销售额 / 花费；</p>
                            <p>例如，RoAS为2，说明花10美元做广告，带来了20美元的销售额。</p><br>
                            <p>RoAS是衡量广告效果投入成本的效率指标，一般情况下，越大越好，亚马逊上的平均RoAS约为3，消费类电子产品的RoAS约为9，玩具和游戏的RoAS约为4.5，</p>
                        </div>
                        <span class="el-icon-question"></span>
                    </el-tooltip>
                </span>
                <span v-else>{{item.name}}</span>
            </template>
            <template slot-scope="scope"> 
                <div v-if="item.name=='起止日期'">
                    <p>{{scope.row.startDate}}</p>
                    <p>{{scope.row.endDate}}</p>
                </div>
                <el-link @click="changeGroup(scope.row)" v-if="item.name=='广告组合'" style="display:inline-block;border-bottom:1px dashed #999999">{{scope.row.portfolioName}}</el-link>
                <el-link style="display:inline-block;border-bottom:1px dashed #999999" @click="changeDailyBudget(scope.row,scope.$index)" v-else-if="item.name=='每日预算'">{{currency}} {{scope.row.dailyBudget}}</el-link>
                <p v-else-if="item.name=='超出预算状态'">
                    <span style="color:red" v-if="scope.row.budgetCost-(scope.row.dailyBudget * 0.9)>0">超出预算</span>
                    <span style="color:orange" v-else-if="scope.row.budgetCost-(scope.row.dailyBudget * 0.9)<0 && scope.row.budgetCost-(scope.row.dailyBudget * 0.8)>0">即将超出</span>
                    <span v-else-if="scope.row.budgetCost-(scope.row.dailyBudget * 0.8)<0" style="color:#13CE66">投放中</span>
                </p>
                <p v-else>
                    <span v-if="item.currency&&scope.row[item.prop]!='-'">{{currency}}</span>{{scope.row[item.prop]}}
                </p>
            </template>
        </el-table-column>
    </el-table>
    <div style="text-align:center;margin:10px">
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageInfo.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>
    </div>
    <el-dialog
        title="新增广告活动"
        :visible.sync="addActive"
        width="70%"
        :before-close="addActiveClose">
        <div>
            <el-divider><span style="font-size:18px">广告活动</span></el-divider>
            <el-form class="add" ref="addActiveInfo" :inline="true" :rules='addActiveRule'  :model="addActiveInfo" label-width="110px">
                <el-form-item label="广告活动名称" prop="name">
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:19px">广告名称尽自己可见<br/>请设置一个您可以轻松识别并在日后参考的名称。</div>
                        <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                    </el-tooltip>
                    <el-input v-model="addActiveInfo.name" style="width:318px;margin-right:50px" placeholder="示例：季节性商品"></el-input>
                </el-form-item>
                <el-form-item label="广告组合">
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:19px">是一组可以设置预算上限的广告活动<br/>组合使您可以对广告活动进行分组和组织。</div>
                        <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                    </el-tooltip>
                    <el-select clearable v-model="addActiveInfo.portfolioId" style="width:318px"  placeholder="无组合">
                        <el-option 
                        :label="item.value" 
                        :value="item.id"
                        v-for="item in portfoliosList"
                        :key="item.id"></el-option>
                    </el-select>
                </el-form-item><br>
                <el-form-item label="预算起止日期">
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:19px">1、您可以设置未来的某个日期作为开始日期，以便在该日期启动广告活动。<br/>2、为确保广告始终投放而不错过展示或点击机会，可选择不选择保持“无结束日期”。<br/>3、您可以在广告活动开展期间随时延长、缩短或结束广告活动。。</div>
                        <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                    </el-tooltip>
                    <el-date-picker
                    v-model="addActiveInfo.startDate"
                    type="date"
                    :clearable="false"
                    placeholder="选择日期"
                    style="width:150px"
                    value-format='yyyy-MM-dd'
                    :picker-options="setStart"
                    @change='Start'>
                    </el-date-picker>
                    ~
                    <el-date-picker
                    v-model="addActiveInfo.endDate"
                    type="date"
                    style="width:150px;margin-right:50px"
                    placeholder="选择日期"
                    value-format='yyyy-MM-dd'
                    :picker-options="setEnd"
                    @change='End'>
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="每日预算" prop="dailyBudget">
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:19px">您愿意每天为该广告活动支出的金额。<br/>如果广告活动支出低于您的每日预算，则这笔差额可用于增加该日历月<br/>的其他日子的每日预算，最多可增加 10%。</div>
                        <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                    </el-tooltip>
                    <el-input 
                    oninput ="value=value.replace(/[^0-9.]/g,'')"
                    :onkeyup="addActiveInfo.dailyBudget<0?addActiveInfo.dailyBudget='':addActiveInfo.dailyBudget"
                    v-model="addActiveInfo.dailyBudget" 
                    style="width:318px" 
                    placeholder="每日预算"></el-input> {{currency}}
                </el-form-item><br>
                <el-form-item label="定向策略">
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:19px">自动投放：亚马逊将定向到关键词和与您广告中的商品类似的商品。<br/>手动投放：选择可定向到顾客搜索的关键词或商品并设置自定义竞价。</div>
                        <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                    </el-tooltip>
                        <el-radio v-model="addActiveInfo.targetingType" label="auto">自动投放</el-radio><br>
                        <p style="margin-left:30px;color:#999999">亚马逊将定向到关键词和与您广告中的商品类似的商品。</p>
                        <el-radio v-model="addActiveInfo.targetingType" style="margin-left:10px" label="manual">手动投放</el-radio><br>
                        <p style="margin-left:30px;color:#999999">选择可定向到顾客搜索的关键词或商品并设置自定义竞价。</p>
                </el-form-item><br>
                <el-form-item label="活动竞价策略">
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:19px;width:300px">
                            广告点击付费的方式。<br/>
                            1、仅降低：当您的广告不太可能带来销售时，我们将实时降低您的竞价。<br>
                            2、提高和降低：当您的广告很有可能带来销售时，我们将实时提高您的竞价（最高可达 100%），并在您的广告不太可能带来销售时降低您的竞价。<br>
                            3、固定竞价：我们将使用您的确切竞价和您设置的任何手动调整，而不会根据售出可能性对您的竞价进行更改。</div>
                        <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                    </el-tooltip>
                        <el-radio v-model="addActiveInfo.bidding.strategy" label="legacyForSales">动态竞价-只降低</el-radio><br>
                        <p style="margin-left:30px;color:#999999">当您的广告转化为订单的可能性较小时，我们将实时调低您的竞价。</p>
                        <el-radio v-model="addActiveInfo.bidding.strategy"  style="margin-left:10px" label="autoForSales">动态竞价 – 提高和降低</el-radio><br>
                        <p style="margin-left:30px;color:#999999">当您的广告很有可能带来销售时，我们将实时提高您的竞价（最高可达 100%）并在您的广告不太可能带来销售时降低您的竞价。</p>
                        <el-radio v-model="addActiveInfo.bidding.strategy"  style="margin-left:10px" label="manual">固定竞价</el-radio><br>
                        <p style="margin-left:30px;color:#999999">我们将使用您的确切竞价和您设置的任何手动调整，而不会根据售出可能性对您的竞价进行更改。</p>
                </el-form-item><br>
                <el-form-item label="根据广告位调整竞价(取代“竞价+”)" label-width="250px">
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:19px;width:300px">
                            <p>通过输入相对默认竞价提高的百分比，按广告位应用不同竞价。</p><br>
                            <p>这些调整将应用于广告活动中的所有竞价。 您的竞价可以进一步更改，具体取决于您的竞价策略。 <a target="top" href="https://advertising.amazon.com/help/ref=ams_head_help?entityId=ENTITY3VAZBWP88M62S#GYQY2B3R58ZHSHJJ">了解更多</a></p>
                        </div>
                        <span class="iconfont icon-wenhao"></span>
                    </el-tooltip>
                    <span style="margin: 0 5px 0 0px;color:#999999;font-size:14px">除了您的竞价策略外，您还可以将出价提高高达900%。<el-link type="primary" target='top' href='https://advertising.amazon.com/help?entityId=ENTITY3VAZBWP88M62S#GYQY2B3R58ZHSHJJ'>了解更多信息>></el-link></span>
                </el-form-item><br>
                <el-form-item label="搜索结果顶部(首页)" label-width="150px">
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:19px">
                            在搜索结果首页顶行的商品推广广告位。
                        </div>
                        <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                    </el-tooltip>
                    <el-input-number v-model="placementTop" controls-position="right" @change="handleChange" :min="0" :max="100"></el-input-number> %
                </el-form-item>
                <el-form-item label="商品页面" label-width="150px">
                    <el-tooltip placement="top">
                        <div slot="content" style="line-height:19px;width:200px">
                            商品详情页面上的商品推广广告位，以及所有非搜索结果广告位。例如“加入购物车”页面。
                        </div>
                        <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                    </el-tooltip>
                    <el-input-number v-model="placementProductPage" controls-position="right" @change="handleChange" :min="0" :max="100"></el-input-number> %
                </el-form-item>
                <p style="color:#999999;font-size:14px;padding-left:120px">示例：假如默认竞价 = $1.00，上方输入的值 = 50%，则竞价将 = 1.00 * (1+50%) = $1.50。</p>
            </el-form>
        </div>
        <div style="margin-top:60px" v-if="false">
            <!-- 新增广告组 -->
            <el-divider><span style="font-size:18px">广告组</span> <span>展开</span><el-link type='primary' @click="atOnce">(同时创建广告组)<i :class="meanwhile?'el-icon-arrow-up':'el-icon-arrow-down'"></i></el-link></el-divider>
            <div v-if="meanwhile">
                <span style="display:inline-block;width:200px;text-align:right">广告组名称</span> <el-input placeholder="示例：季节性商品" style="width:65%"></el-input><br>
                <span style="display:inline-block;width:200px;text-align:right">商品</span> 
                <el-select style="width:140px;margin:10px 10px 0 5px" placeholder="商品状态">
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                </el-select>
                <el-select style="width:140px;margin:10px 10px 0 5px" placeholder="品牌">
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                </el-select>
                <el-select style="width:140px;margin:10px 10px 0 5px" placeholder="分组标签">
                    <el-option label="区域一" value="shanghai"></el-option>
                    <el-option label="区域二" value="beijing"></el-option>
                </el-select>
                <el-input style="width:300px;margin-right:10px" placeholder="请输入ASIN"  class="input-with-select">
                    <el-select clearable @clear="clearType" style="width:130px" v-model="select" slot="prepend" placeholder="选择输入类型">
                        <el-option 
                        :label="item.value"
                        :value="item.id"
                        v-for="item in searchList"
                        :key="item.id"></el-option>
                    </el-select>
                </el-input>
                <el-button type="primary" size="mini">立即查询</el-button>
                <div class="Box">
                    <div>
                        <el-tabs v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane label="搜索" name="first"></el-tab-pane>
                            <el-tab-pane label="输入列表" name="second">
                                <span slot="label" v-if="activeName=='second'">
                                    <span style="margin-right:10px">输入列表</span>
                                    <el-radio v-model="inputList" label="子ASIN"></el-radio>
                                    <el-radio v-model="inputList" label="父ASIN"></el-radio>
                                </span>
                            </el-tab-pane>
                        </el-tabs>
                        <el-button type="text" class='all'>添加全部</el-button>
                        <div style="height:480px;overflow:auto">
                            <div class="item" v-for="item in goodsList" :key="item.id">
                                <div class="pic">
                                    <img :src="item.imageUrl" alt="" width="100%">
                                </div>
                                <div class="SKU">
                                    <p>
                                        <span class="state"><img src="" alt="" width="100%"></span><span>子 B088W91HJ6</span>
                                    </p>
                                    <p>
                                        <span>SKU:SD-00109DAA</span>
                                    </p>
                                </div>
                                <div class="realizable">
                                    <p>可售库存：{{item.total}}</p>
                                    <p>可售天数：{{item.canSellDay}}</p>
                                </div>
                                <div class="price">
                                    <p>售价：{{currency}}{{item.sellPrice}}</p>
                                </div>
                                <div style="font-weight:blod;font-size:23px;color:#409EFF;width:30px"><span @click="toAdded(item)" v-if="item.operation==0" class="el-icon-circle-plus-outline"></span></div>
                            </div>
                        </div>
                        <div style="text-align:right;padding-top:20px">
                            <el-pagination
                            background
                            layout="prev, pager, next"
                            :total="goodsTotal">
                            </el-pagination>
                        </div>
                    </div>
                    <div>
                        <el-tabs v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane label="已添加" name="first"></el-tab-pane>
                        </el-tabs>
                        <el-button type="text" class='all'>删除全部</el-button>
                        <div style="height:480px;overflow:auto">
                            <template  v-for="item in goodsList" >
                            <div class="item" :key="item.id" v-if="item.operation==1">
                                <div class="pic">
                                    <img :src="item.imageUrl" alt="" width="100%">
                                </div>
                                <div class="SKU">
                                    <p>
                                        <span class="state"><img src="" alt="" width="100%"></span><span>子 B088W91HJ6</span>
                                    </p>
                                    <p>
                                        <span>SKU:SD-00109DAA</span>
                                    </p>
                                </div>
                                <div class="realizable">
                                    <p>可售库存：{{item.total}}</p>
                                    <p>可售天数：{{item.canSellDay}}</p>
                                </div>
                                <div class="price">
                                    <p>售价：{{currency}}{{item.sellPrice}}</p>
                                </div>
                                <div style="font-weight:blod;font-size:23px;color:#409EFF;width:30px"><span  @click="toGoods(item)" class="el-icon-delete"></span></div>
                            </div>
                            </template>
                        </div>
                        <div style="text-align:right;padding-top:20px">
                            <el-pagination
                            background
                            layout="prev, pager, next"
                            :total="10">
                            </el-pagination>
                        </div>
                    </div>
                </div>
                <el-form label-width="80px" style="margin:0 40px">
                    <el-form-item label="默认竞价">
                        <el-tooltip placement="top">
                            <div slot="content" style="line-height:19px">
                                默认竞价适用于所有点击，除非您为关键词设置不同的竞价。<br>
                                您的竞价可能会发生变化，具体取决于您选择的竞价策略和广告位竞价涨幅。
                            </div>
                            <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                        </el-tooltip>
                        <el-input style="width:150px" placeholder=""></el-input>
                    </el-form-item>
                    <el-form-item label="默认竞价">
                        <el-tooltip placement="top">
                            <div slot="content" style="line-height:19px">
                                字段解释
                            </div>
                            <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                        </el-tooltip>
                        <el-switch active-color="#13ce66" inactive-color="#AAAAAA"></el-switch><br>
                        <div>
                            <el-switch style="margin-left:12px" active-color="#13ce66" inactive-color="#AAAAAA"></el-switch>
                            紧密匹配 <el-input style="width:150px" placeholder=""></el-input>  不低于 2，不高于100000。 <br>
                            <el-switch style="margin-left:12px" active-color="#13ce66" inactive-color="#AAAAAA"></el-switch>
                            宽泛匹配 <el-input style="width:150px" placeholder=""></el-input>  不低于 2，不高于100000。 <br>
                            <el-switch style="margin-left:12px" active-color="#13ce66" inactive-color="#AAAAAA"></el-switch>
                            同类商品 <el-input style="width:150px" placeholder=""></el-input>  不低于 2，不高于100000。 <br>
                            <el-switch style="margin-left:12px" active-color="#13ce66" inactive-color="#AAAAAA"></el-switch>
                            关联商品 <el-input style="width:150px" placeholder=""></el-input>  不低于 2，不高于100000。 <br>
                        </div>
                    </el-form-item>
                    
                </el-form>

                <div class="words">
                    <el-collapse accordion style="margin-bottom:18px">
                        <el-collapse-item>
                            <template slot="title">
                            <span style="margin-left:20px">否定关键词（可选）</span><i class="iconfont icon-wenhao"></i>
                            </template>
                            <div class="keyword" style="border-top: 1px solid #F0F0F0;padding:0 10px">
                                <div>
                                    <span>匹配类型</span>
                                    <el-tooltip placement="top">
                                        <div slot="content" style="line-height:19px">
                                            字段解释
                                        </div>
                                        <span class="iconfont icon-wenhao" style="margin-right:9px"></span>
                                    </el-tooltip>
                                    <el-radio label="否定精准"></el-radio>
                                    <el-radio label="否定词组"></el-radio><br>
                                    <el-input rows="8" type="textarea" placeholder="输入您的列表并用新行将每项隔开"></el-input>
                                    <el-button style="float:right;margin:10px 0px 0 15px">添加关键词</el-button>
                                </div>
                                <div>
                                    <span>已添加</span>
                                    <el-button type="text" style="float:right">删除全部</el-button>
                                    <el-table
                                        :data="data"
                                        style="width: 100%"
                                        header-cell-style="background:#DFEFEFE;font-size:14px">
                                        <el-table-column
                                            prop="prop"
                                            label="关键词"
                                            width="width">
                                        </el-table-column>
                                        <el-table-column
                                            prop="prop"
                                            label="匹配类型"
                                            width="90">
                                        </el-table-column>
                                        <el-table-column
                                            prop="prop"
                                            header-align="center"
                                            label="删除"
                                            width="90">
                                            <template slot-scope="scope">
                                                <el-button type="text" class="el-icon-delete"></el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                   </div> 
                   <div class="words"> 
                    <el-collapse accordion>
                        <el-collapse-item>
                            <template slot="title">
                            <span style="margin-left:20px">否定商品（可选）</span><i class="iconfont icon-wenhao"></i>
                            </template>
                            <div class="keyword" style="border-top: 1px solid #F0F0F0;padding:0 10px">
                                <div>
                                    <span style="margin-bottom:10px">排除全部</span>
                                    <el-tooltip placement="top">
                                        <div slot="content" style="line-height:19px">
                                            字段解释
                                        </div>
                                        <span class="iconfont icon-wenhao" style="margin-right:9px"></span>
                                    </el-tooltip>
                                    <el-input rows="8" type="textarea" placeholder="手动输入ASIN，多个时换行输入"></el-input>
                                    <el-button style="float:right;margin:10px 0px 0 15px">添加</el-button>
                                </div>
                                <div>
                                    <span>已添加</span>
                                    <el-button type="text" style="float:right">删除全部</el-button>
                                    <el-table
                                        :data="data"
                                        style="width: 100%"
                                        header-cell-style="background:#DFEFEFE;font-size:14px">
                                        <el-table-column
                                            prop="prop"
                                            label="品牌/商品"
                                            width="width">
                                        </el-table-column>
                                        <el-table-column
                                            prop="prop"
                                            header-align="center"
                                            label="删除"
                                            width="90">
                                            <template slot-scope="scope">
                                                <el-button type="text" class="el-icon-delete"></el-button>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
        </div>
        <p style="text-align:right;margin:40px 40px 0 0">
            <el-button :loading="buttonLoading" type='primary' @click="sureActive">启动广告活动</el-button>
        </p>
    </el-dialog>
    <!-- 底部抽屉区域 -->
    <el-drawer
      title="广告活动表现"
      :modal="false"
      size="70%"
      :visible.sync="drawer"
      :direction="direction"
      :custom-class="DrawerClass"
      :before-close="handleClose"
    >
    <TendencyChart 
    :list.sync='ListOfData' 
    :info='`广告活动：${campaignName}`'
    :legendList='legendList'
    :currency="currency"
    @analysisClick='analysisClick'></TendencyChart>
    </el-drawer>
    <el-dialog
        title="批量添加/变更组合"
        :visible.sync="addChangeBox"
        width="25%"
        :before-close="dialogBeforeClose">
        <div style="text-align:center">
            <p>如果选中的广告活动，当前已有广告组合，则确定后会更新。</p>
            <el-select v-model="batchInfo.portfoliosId" style="width:70%;margin-top:15px" placeholder="请输入/选择组合">
                <el-option 
                :label="item.value"  
                :value="item.id"
                v-for="item in portfoliosList"
                :key="item.id"></el-option>
            </el-select>
        </div>
        <div slot="footer">
            <el-button @click="addChange('cancel')">取 消</el-button>
            <el-button type="primary" @click="addChange">确 定</el-button>
        </div>
    </el-dialog>
    <el-dialog
        title="批量调整开始日期"
        :visible.sync="setBeginBox"
        width="25%"
        :before-close="dialogBeforeClose">
        <div style="text-align:center">
            <el-date-picker
            v-model="batchInfo.date"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择开始日期"
            :picker-options="setStart">
            </el-date-picker>
        </div>
        <div slot="footer">
            <el-button @click="setBegin('cancel')">取 消</el-button>
            <el-button type="primary" @click="setBegin">确 定</el-button>
        </div>
    </el-dialog>
    <el-dialog
        title="批量调整结束日期"
        :visible.sync="setEndBox"
        width="26%"
        :before-close="dialogBeforeClose">
        <div style="text-align:center">
            <p style="line-height:20px;margin-bottom:20px">选中的广告活动中，最大的开始日期为：{{maxStart}}，<br>新的结束日期不能小于此日期。</p>
            <el-radio v-model="radio" label="无结束日期"></el-radio>
            <el-radio v-model="radio" label="选择日期"></el-radio>
            <el-date-picker
            clearable
            v-model="batchInfo.date"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择结束日期"
            :picker-options="setEnd"
            @change="selectEnd"
            :disabled="radio=='选择日期'?false:true"
            style="margin-top:20px"
            >
            </el-date-picker>
        </div>
        <div style="text-align:right;margin:20px 20px 0 0">
            <el-button @click="setEndTime('cancel')">取 消</el-button>
            <el-button type="primary" @click="setEndTime">确 定</el-button>
        </div>
    </el-dialog>
    <el-dialog
        title="批量调整每日预算"
        :visible.sync="setBudgetBox"
        width="30%"
        :before-close="dialogBeforeClose">
        <div style="line-height:20px;padding-left:20px">
            <p style="text-align:center;margin-bottom:20px;margin-right:20px">
                <el-select style="width:200px" v-model="batchInfo.dailyBudgetUpdateReq.operateType" placeholder="请选择活动区域">
                    <el-option 
                    :label="item.values" 
                    :value="item.id"
                    v-for="item in budgetOperateTypeList"
                    :key="item.id"
                    @click.native='selectBudget(item.values)'></el-option>
                </el-select> ~
                <el-input v-model="value" style="width:200px" placeholder="">
                    <template v-if="unit=='amount'" slot="prepend">{{currency}}</template>
                    <template v-else-if="unit=='percent'" slot="append">%</template>
                </el-input>
            </p>
            <p style="color:#A2A2A2;font-size:14px">说明</p>
            <p style="color:#A2A2A2;font-size:14px">1、将预算统一调整到：输入的金额 就是 目标预算；</p>
            <p style="color:#A2A2A2;font-size:14px">2、将预算按百分比提高/降低： 目标预算 = (1 ± 输入值) / 100 * 当前的预算；</p>
            <p style="color:#A2A2A2;font-size:14px">3、将预算按固定金额增加/减少： 目标预算 = 输入的金额 ± 当前的预算；</p>
            <p style="color:#A2A2A2;font-size:14px">4、调整后的目标预算，小于1时，系统会将目标预算调整为1；</p>
            <p style="color:#A2A2A2;font-size:14px">5、调整后的目标预算，按四舍五入最多保留两位小数；</p>
        
        </div>
        <div style="text-align:right;margin:20px 20px 0 0">
            <el-button @click="getBudget('cancel')">取 消</el-button>
            <el-button type="primary" @click="getBudget">确 定</el-button>
        </div>
    </el-dialog>
    <el-dialog
        :title="title"
        :visible.sync="addGroupBox"
        width="37%"
        :before-close="addGroupBoxClose">
        <div>
            <el-form ref="addGroupInfo" :rules="groupRule" :model="addGroupInfo"  label-width="200px">
                <el-form-item label="广告组合名称" prop="name">
                    <el-input v-model="addGroupInfo.name" style="width:70%" placeholder="请输入组合名称"></el-input>
                </el-form-item>
                <el-form-item label="预算上限">
                    <el-select v-model="addGroupInfo.budget.policy" style="width:70%">
                        <el-option 
                        :label="item.value" 
                        :value="item.id"
                        v-for="item in portfoliosBudgetTypeList"
                        :key="item.id" @click.native="budgetEnd(item.id)"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="addGroupInfo.budget.policy=='monthlyRecurring'" label="每月预算上限" prop="amount1">
                    <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="addGroupInfo.budget.amount" style="width:70%">
                        <span slot="prepend">{{currency}}</span>
                    </el-input>
                </el-form-item>
                <el-form-item label="预算结束日期" v-if="addGroupInfo.budget.policy=='monthlyRecurring'">
                    <el-radio v-model="groupEnd" label="永不"></el-radio>
                    <el-radio v-model="groupEnd" label="开启"></el-radio>
                    <el-date-picker
                    v-model="addGroupInfo.budget.endDate"
                    type="date"
                    style="width:160px"
                    value-format="yyyy-MM-dd"
                    placeholder="结束日期"
                    :picker-options="setEnd"
                    :disabled="groupEnd=='开启'?false:true">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束日期" v-if="addGroupInfo.budget.policy=='dateRange'">
                    <el-date-picker
                    v-model="addGroupInfo.budget.startDate"
                    type="date"
                    :clearable="false"
                    placeholder="开始日期"
                    style="width:150px"
                    :picker-options="setEnd"
                    value-format='yyyy-MM-dd'
                    >
                    </el-date-picker>
                    ~
                    <el-date-picker
                    v-model="addGroupInfo.budget.endDate"
                    type="date"
                    style="width:150px;margin-right:50px"
                    placeholder="结束日期"
                    :picker-options="setEnd"
                    value-format='yyyy-MM-dd'
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item v-if="addGroupInfo.budget.policy=='dateRange'" label="期间预算上限" prop="amount2">
                    <el-input oninput="value=value.replace(/^\.+|[^\d.]/g,'')" v-model="addGroupInfo.budget.amount" style="width:70%">
                        <span slot="prepend">{{currency}}</span>
                    </el-input>
                </el-form-item>

            </el-form>
            <p style="margin-top:20px;line-height:24px">预算上限：为某一日期范围设置预算上限或设置为每月重复。当您的支出达到预算上限金额或达到预算结束日期时，您的广告活动将停止投放。</p>
        </div>
        <div slot="footer">
            <el-button @click="addGroupBoxClose">取 消</el-button>
            <el-button type="primary" :loading='buttonLoading' @click="addADSgroup">确 定</el-button>
        </div>
    </el-dialog>
    <el-dialog
        title="更改广告活动"
        :visible.sync="updateActiveBox"
        width="70%"
        :before-close="updateActiveBoxClose">
        <div v-loading=buttonLoading>
            <el-tabs v-model="selectUpdateActive" style="margin-bottom:40px" @tab-click="getListNegativeTarget">
                <el-tab-pane label="广告活动设置" name="setActive"></el-tab-pane>
                <el-tab-pane label="否定投放" name="noLaunch"></el-tab-pane>
            </el-tabs>
            <div v-if="selectUpdateActive=='setActive'">
                <el-divider><span style="font-size:18px">广告活动</span></el-divider>
                <el-form class="add" ref="addActiveInfo" :inline="true" :rules='addActiveRule'  :model="addActiveInfo" label-width="110px">
                    <el-form-item label="广告活动名称" prop="name">
                        <el-tooltip placement="top">
                            <div slot="content" style="line-height:19px">广告名称尽自己可见<br/>请设置一个您可以轻松识别并在日后参考的名称。</div>
                            <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                        </el-tooltip>
                        <el-input v-model="addActiveInfo.name" style="width:318px;margin-right:50px" placeholder="示例：季节性商品"></el-input>
                    </el-form-item>
                    <el-form-item label="广告组合" prop="portfolioId">
                        <el-tooltip placement="top">
                            <div slot="content" style="line-height:19px">是一组可以设置预算上限的广告活动<br/>组合使您可以对广告活动进行分组和组织。</div>
                            <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                        </el-tooltip>
                        <el-select clearable v-model="addActiveInfo.portfolioId" style="width:318px"  placeholder="无组合">
                            <el-option 
                            :label="item.value" 
                            :value="item.id"
                            v-for="item in portfoliosList"
                            :key="item.id"></el-option>
                        </el-select>
                    </el-form-item><br>
                    <el-form-item label="预算起止日期">
                        <el-tooltip placement="top">
                            <div slot="content" style="line-height:19px">1、您可以设置未来的某个日期作为开始日期，以便在该日期启动广告活动。<br/>2、为确保广告始终投放而不错过展示或点击机会，可选择不选择保持“无结束日期”。<br/>3、您可以在广告活动开展期间随时延长、缩短或结束广告活动。。</div>
                            <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                        </el-tooltip>
                        <el-date-picker
                        v-model="addActiveInfo.startDate"
                        type="date"
                        :clearable="false"
                        placeholder="选择日期"
                        style="width:150px"
                        value-format='yyyy-MM-dd'
                        :picker-options="setStart"
                        @change='Start'>
                        </el-date-picker>
                        ~
                        <el-date-picker
                        v-model="addActiveInfo.endDate"
                        type="date"
                        style="width:150px;margin-right:50px"
                        placeholder="选择日期"
                        value-format='yyyy-MM-dd'
                        :picker-options="setEnd"
                        @change='End'>
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="每日预算" prop="dailyBudget">
                        <el-tooltip placement="top">
                            <div slot="content" style="line-height:19px">您愿意每天为该广告活动支出的金额。<br/>如果广告活动支出低于您的每日预算，则这笔差额可用于增加该日历月<br/>的其他日子的每日预算，最多可增加 10%。</div>
                            <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                        </el-tooltip>
                        <el-input oninput ="value=value.replace(/[^0-9.]/g,'')" v-model="addActiveInfo.dailyBudget" style="width:318px" placeholder="每日预算"></el-input> {{currency}}
                    </el-form-item><br>
                    <el-form-item label="定向策略">
                        <el-tooltip placement="top">
                            <div slot="content" style="line-height:19px">自动投放：亚马逊将定向到关键词和与您广告中的商品类似的商品。<br/>手动投放：选择可定向到顾客搜索的关键词或商品并设置自定义竞价。</div>
                            <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                        </el-tooltip>
                            <el-radio disabled v-model="addActiveInfo.targetingType" label="auto">自动投放</el-radio><br>
                            <p style="margin-left:30px;color:#999999">亚马逊将定向到关键词和与您广告中的商品类似的商品。</p>
                            <el-radio disabled v-model="addActiveInfo.targetingType" style="margin-left:10px" label="manual">手动投放</el-radio><br>
                            <p style="margin-left:30px;color:#999999">选择可定向到顾客搜索的关键词或商品并设置自定义竞价。</p>
                    </el-form-item><br>
                    <el-form-item label="活动竞价策略">
                        <el-tooltip placement="top">
                            <div slot="content" style="line-height:19px;width:300px">
                                广告点击付费的方式。<br/>
                                1、仅降低：当您的广告不太可能带来销售时，我们将实时降低您的竞价。<br>
                                2、提高和降低：当您的广告很有可能带来销售时，我们将实时提高您的竞价（最高可达 100%），并在您的广告不太可能带来销售时降低您的竞价。<br>
                                3、固定竞价：我们将使用您的确切竞价和您设置的任何手动调整，而不会根据售出可能性对您的竞价进行更改。</div>
                            <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                        </el-tooltip>
                            <el-radio v-model="addActiveInfo.bidding.strategy" label="legacyForSales">动态竞价-只降低</el-radio><br>
                            <p style="margin-left:30px;color:#999999">当您的广告转化为订单的可能性较小时，我们将实时调低您的竞价。</p>
                            <el-radio v-model="addActiveInfo.bidding.strategy"  style="margin-left:10px" label="autoForSales">动态竞价 – 提高和降低</el-radio><br>
                            <p style="margin-left:30px;color:#999999">当您的广告很有可能带来销售时，我们将实时提高您的竞价（最高可达 100%）并在您的广告不太可能带来销售时降低您的竞价。</p>
                            <el-radio v-model="addActiveInfo.bidding.strategy"  style="margin-left:10px" label="manual">固定竞价</el-radio><br>
                            <p style="margin-left:30px;color:#999999">我们将使用您的确切竞价和您设置的任何手动调整，而不会根据售出可能性对您的竞价进行更改。</p>
                    </el-form-item><br>
                    <el-form-item label="根据广告位调整竞价(取代“竞价+”)" label-width="250px">
                        <el-tooltip placement="top">
                            <div slot="content" style="line-height:19px;width:300px">
                                <p>通过输入相对默认竞价提高的百分比，按广告位应用不同竞价。</p><br>
                                <p>这些调整将应用于广告活动中的所有竞价。 您的竞价可以进一步更改，具体取决于您的竞价策略。 <a target="top" href="https://advertising.amazon.com/help/ref=ams_head_help?entityId=ENTITY3VAZBWP88M62S#GYQY2B3R58ZHSHJJ">了解更多</a></p>
                            </div>
                            <span class="iconfont icon-wenhao"></span>
                        </el-tooltip>
                        <span style="margin: 0 5px 0 0px;color:#999999;font-size:14px">除了您的竞价策略外，您还可以将出价提高高达900%。<el-link type="primary" target='top' href='https://advertising.amazon.com/help?entityId=ENTITY3VAZBWP88M62S#GYQY2B3R58ZHSHJJ'>了解更多信息>></el-link></span>
                    </el-form-item><br>
                    <el-form-item label="搜索结果顶部(首页)" label-width="150px">
                        <el-tooltip placement="top">
                            <div slot="content" style="line-height:19px">
                                在搜索结果首页顶行的商品推广广告位。
                            </div>
                            <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                        </el-tooltip>
                        <el-input-number v-model="placementTop" controls-position="right" @change="handleChange" :min="0" :max="100"></el-input-number> %
                    </el-form-item>
                    <el-form-item label="商品页面" label-width="150px">
                        <el-tooltip placement="top">
                            <div slot="content" style="line-height:19px;width:200px">
                                商品详情页面上的商品推广广告位，以及所有非搜索结果广告位。例如“加入购物车”页面。
                            </div>
                            <span class="iconfont icon-wenhao" style="margin: 0 5px 0 -10px"></span>
                        </el-tooltip>
                        <el-input-number v-model="placementProductPage" controls-position="right" @change="handleChange" :min="0" :max="100"></el-input-number> %
                    </el-form-item>
                    <p style="color:#999999;font-size:14px;padding-left:120px">示例：假如默认竞价 = $1.00，上方输入的值 = 50%，则竞价将 = 1.00 * (1+50%) = $1.50。</p>
                </el-form>
            </div>
            <div v-if="selectUpdateActive=='noLaunch'">
                <div class="words">
                    <el-collapse accordion style="margin-bottom:18px">
                        <el-collapse-item>
                            <template slot="title">
                            <span style="margin-left:20px">否定关键词</span>
                            <el-tooltip placement="top">
                                <div slot="content" style="width:250px;line-height:19px">
                                    <p>当顾客的搜索词与您的否定关键词匹配时，否定关键词会阻止您的广告展示。</p>
                                    <p>您可以排除不相关的搜索，从而降低广告成本。</p>
                                </div>
                                <i class="iconfont icon-wenhao"></i>
                            </el-tooltip>
                            </template>
                            <div class="keyword" style="border-top: 1px solid #F0F0F0;padding:0 10px">
                                <div>
                                    <span>匹配类型</span>
                                    <el-tooltip placement="top">
                                        <div slot="content" style="line-height:19px;width:250px">
                                            <p>匹配类型让您能够微调哪些顾客搜索内容会触发您的广告。</p><br>
                                            <p>广泛匹配： 包含以任何顺序排列的所有关键词，包括复数、变体和相关关键词。</p>
                                            <p>词组匹配： 包含确切的词组或词序相同的关键词。</p>
                                            <p>精准匹配： 与相应关键词或关键词的词序完全匹配。</p>
                                        </div>
                                        <span class="iconfont icon-wenhao" style="margin-right:9px"></span>
                                    </el-tooltip>
                                    <el-radio v-model="matchType" label="negativeExact">否定精准</el-radio>
                                    <el-radio v-model="matchType" label="negativePhrase">否定词组</el-radio><br>
                                    <el-input v-model="noKeywords" rows="8" type="textarea" placeholder="输入您的列表并用新行将每项隔开"></el-input>
                                    <el-button style="float:right;margin:10px 0px 0 15px" @click="addNoKey">添加关键词</el-button>
                                </div>
                                <div v-loading="dialogLoading">
                                    <span style="margin-right:10px;color:#409EFF;cursor: pointer;" v-show="addedNoKeyword!=0" @click="updateNeKeyword">归档</span>
                                    <span>已存在{{addedNoKeyword}}个，并添加{{noLaunchInfo.neKeywordAddReqList.length - addedNoKeyword}}</span>
                                    <el-button type="text" style="float:right" @click="delAllkeyword">删除全部</el-button>
                                    <el-table
                                        :data="noLaunchInfo.neKeywordAddReqList"
                                        height="300"
                                        style="width: 100%;"
                                        cell-style="font-size:14px"
                                        @selection-change="selectNoKeywords"
                                        header-cell-style="background:#FAFAFA;font-size:14px">
                                        <el-table-column
                                        type="selection"
                                        width="55"
                                        :selectable="archivedKeywordSelect"
                                        ></el-table-column>
                                        <el-table-column
                                            prop="keywordText"
                                            label="关键词"
                                            width="width">
                                        </el-table-column>
                                        <el-table-column
                                            prop="matchType"
                                            label="匹配类型"
                                            width="90">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.matchType=='negativeExact'">否定精准</span>
                                                <span v-else>否定词组</span>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="prop"
                                            align="center"
                                            header-align="center"
                                            label="删除"
                                            width="90">
                                            <template slot-scope="scope">
                                                <el-button v-if="scope.row.operation!=1" @click="delNegativeKeyword(scope.$index,scope.row.keywordText)" style="font-size:23px;" type="text" class="el-icon-delete"></el-button>
                                                <span v-if="scope.row.operation==1" style="color:#999999;font-size:14px">已存在</span>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                            <el-button type='primary' style="float:right;margin:5px" @click="addNegativeKeyword" :loading='buttonLoading'>确定新增否定关键词</el-button>
                        </el-collapse-item>
                    </el-collapse>
                </div>
                <div class="words" style="margin-top:20px" v-if="type=='auto'"> 
                    <el-collapse accordion>
                        <el-collapse-item>
                            <template slot="title">
                            <span style="margin-left:20px">否定商品</span>
                            <el-tooltip placement="top">
                                <div slot="content" style="width:250px;line-height:19px">
                                    <p>否定商品投放会防止您的广告在顾客的搜索内容与您的否定商品选择匹配时展示。</p>
                                    <p>这有助于排除不相关的搜索，从而减少广告费用。</p>
                                </div>
                                <i class="iconfont icon-wenhao"></i>
                            </el-tooltip>
                            </template>
                            <div class="keyword" style="border-top: 1px solid #F0F0F0;padding:0 10px">
                                <div>
                                    <el-tabs v-model="noGoodsType" @tab-click="noGoods=''">
                                        <el-tab-pane label="排除全部" name="asinSameAs">
                                            <span slot="label">排除全部
                                                <el-tooltip placement="top">
                                                    <div slot="content" style="width:250px;line-height:19px">
                                                        <p>这是一项高级策略，可防止您的广告展示在特定搜索结果和商品详情页中。</p>
                                                    </div>
                                                    <i class="iconfont icon-wenhao"></i>
                                                </el-tooltip>
                                            </span>
                                        </el-tab-pane>
                                    </el-tabs>
                                    <div>
                                        <el-input v-model="noGoods" style="margin-top:10px" rows="8" type="textarea" placeholder="手动输入ASIN，多个时换行输入"></el-input>
                                        <el-button @click="toTargetsBrandsList" style="float:right;margin:10px 0px 0 15px">添加</el-button>
                                    </div>
                                </div>
                                <div v-loading="dialogLoading">
                                    <span style="margin-right:10px;color:#409EFF;cursor: pointer;" v-show="addedNoGoods!=0"  @click="archivedNeTarget">归档</span>
                                    <span>已存在{{addedNoGoods}}个，并添加{{noLaunchInfo.neTargetAddReqList.length - addedNoGoods}}</span>
                                    <el-button type="text" style="float:right" @click="delAllnoGood">删除全部</el-button>
                                    <el-table
                                        :data="noLaunchInfo.neTargetAddReqList"
                                        style="width: 100%"
                                        @selection-change="selectNoGoods"
                                        header-cell-style="background:#DFEFEFE;font-size:14px">
                                        <el-table-column
                                        type="selection"
                                        width="50"
                                        :selectable="archivedSelect"
                                        ></el-table-column>
                                        <el-table-column
                                            prop="prop"
                                            label="品牌/商品"
                                            width="width">
                                            <template slot-scope="scope">
                                                <p style="color:#999999;font-size:14px">
                                                    <span v-if="scope.row.type=='asinBrandSameAs'&&scope.row.yet!=1">品牌：{{scope.row.name}}</span>
                                                    <span v-if="scope.row.type=='asinSameAs'&&scope.row.yet!=1">ASIN：{{scope.row.value}}</span>
                                                    <span v-if="scope.row.yet==1">
                                                        <span v-if="scope.row.type=='asinBrandSameAs'">品牌：</span>
                                                        <span v-if="scope.row.type=='asinSameAs'">ASIN：</span>
                                                        {{scope.row.value}}
                                                    </span>
                                                </p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column
                                            prop="prop"
                                            header-align="center"
                                            label="删除"
                                            width="70">
                                            <template slot-scope="scope">
                                                <span v-if="scope.row.yet!=1" style="font-size:23px;color:#409EFF;" class="el-icon-delete" @click="delItem(scope.$index,scope.row.name)"></span>
                                                <span style="color:#999999;font-size:14px" v-if="scope.row.yet==1">已存在</span>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </div>
                            </div>
                        <el-button type='primary' style="float:right;margin:5px" @click="addNeTargets" :loading='buttonLoading'>确定新增否定商品定位</el-button>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
        </div>
        <div slot="footer" v-if="selectUpdateActive=='setActive'">
            <el-button @click="updateActiveBoxClose">取 消</el-button>
            <el-button type="primary" @click="update" :loading='buttonLoading'>确 定</el-button>
        </div>
    </el-dialog>
    <!-- 修改预算上限 -->
    <el-dialog
     width="30%"
     title="修改预算上限"
     :visible.sync="budgetVisible"
    >
     <el-form :model="formList" ref="formList" label-width="150px">
      <el-form-item label="商品推广预算上限:">
        <el-select 
         style="width: 60%"
         v-model="formList.goodsPromotion"
        >
          <el-option label="使用广告系列顶级预算" value="0"></el-option>
          <el-option label="使用每日预算上限" value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="金额(¥):" v-show="formList.goodsPromotion=='0'?false:true">
        <el-input type="number" min="1" v-model="formList.money" style="width: 60%"></el-input>
      </el-form-item>
      <p style="margin-bottom: 15px; font-size: 14px">预算上限：您可以选择“使用每日预算”, 此预算将是每天您 帐户中所有商品推广广告系列的最大支出;</p>
      <p style="margin-bottom: 15px; font-size: 14px">或“使用广告系 列顶级预算”, 此预算将是指定日期范围内您帐户中所有商 品推广广告系列的最高支出。</p>
      <p style="margin-bottom: 15px; font-size: 14px"> 当达到商品推广预算上限时, 所有商品推广活动将停止提供。</p>
     </el-form>
     <span slot="footer">
       <el-button @click="budgetVisible = false">关 闭</el-button>
       <el-button type="primary" @click="handleConfirm">确 定</el-button>
     </span>
    </el-dialog>
  </div>
</template>

<script>
import {pageInit,
        listPage,
        addInit,
        addActive,
        batchInit,
        batchUpdate,
        addGroupInit,
        addgroup,
        getGoodsList,
        focusOn,
        focusOff,
        updateInit,
        AmzAdCampaigns,
        listCampaignNegativeKeywords,
        listNegativeTarget,
        addNeTargets,
        addNegativeKeyword,
        archivedNeTarget,
        updateNeKeyword,
        updateBid,
        amzAdPortfolios,
        groupUpdateInit,
        listByCampaign,
        accountBudget
        } from '@/api/Advertising/activity.js'
import tableDialog from '@/components/tableDialog.vue'
import TimeQuantum from '@/components/TimeQuantum.vue'
import TendencyChart from './TendencyChart.vue'
import {fmtDate} from '@/utils/date.js'
import {Digits} from '@/utils/Digits.js'
export default {
    name:'activity',
    components:{
        tableDialog,
        TimeQuantum,
        TendencyChart
    },
    data(){
        return {
            drawer: false,
            direction: 'btt',
            currency:'',
            updateActiveBox:false,
            meanwhile:false,
            loading:false,
            dialogLoading:false,
            addActive:false,
            addGroupBox:false,
            selectUpdateActive:'setActive',
            buttonLoading:false,
            matchType:'negativeExact',
            noKeywords:'',
            tableData:[],
            headList:[
                {name:'投放',prop:'targetingTypeName',width:100},
                {name:'竞价策略',prop:'strategy',width:180},
                {name:'起止日期',prop:'',width:150},
                {name:'广告组合',prop:'portfolioName',width:190},
                {name:'定时策略',prop:'',width:150},
                {name:'每日预算',prop:'dailyBudget',width:181,sortable:true,currency:true},
                {name:'超出预算状态',prop:'',width:140},
                {name:'曝光量',prop:'impressions',width:121,sortable:true},
                {name:'点击量',prop:'clicks',width:121,sortable:true},
                {name:'点击率',prop:'clickRate',width:121,sortable:true},
                {name:'花费',prop:'cost',width:101,sortable:true,currency:true},
                {name:'CPC均价',prop:'clickAveCost',width:120,sortable:true,currency:true},
                {name:'订单数',prop:'attributedConversions14d',width:121,sortable:true},
                {name:'CVR',prop:'cvr',width:101,sortable:true},
                {name:'CPA',prop:'cpa',width:101,sortable:true,currency:true},
                {name:'销售额',prop:'attributedSales14d',width:121,sortable:true,currency:true},
                {name:'ACoS',prop:'acos',width:101,sortable:true},
                {name:'ROAS',prop:'roas',width:101,sortable:true},
            ],
            addChangeBox:false,
            setBeginBox:false,
            setEndBox:false,
            setBudgetBox:false,
            maxStart:'',
            groupEnd:'永不',
            tableHead:[],
            pageInfo:{
                current:'',
                profileId:'',
                portfoliosId:'',
                state:'',
                strategy:'',
                targetingType:'',
                isFavorite:'',
                keyword:'',
                beginTime:'',
                endTime:'',
                sortColumn:'',
                sort:'',
                pageSize:''
            },
            placementTop:'',
            placementProductPage:'',
            addActiveInfo:{
               bidding:{
                strategy:'autoForSales',
                adjustments:[]
               },
               campaignType:'sponsoredProducts',
               dailyBudget:'',
               name:'',
               portfolioId:'',
               premiumBidAdjustment:'',
               startDate:'',
               endDate:'',
               state:'enabled',
               tags:{
                 accountManager:'',
                 ponumber:'',
               },
               targetingType:'auto'
            },
            addActiveRule:{
                name:[{required:true,message:'请填写广告活动名称',trigger:'blur'}],
                dailyBudget:[{required:true,message:'请填写每日预算',trigger:'blur'}],
            },
            noLaunchInfo:{
              neKeywordAddReqList:[],
              neTargetAddReqList:[]
            },
            addNegativeKeywordsInfo:{//新增否定关键词信息
              adGroupId:'',
              amzAdKeywordForGroupAddReqList:[],
              campaignId:'',
              profileId:'',
            },
            addNeTargetsInfo:{
              adGroupId:'',
              amzAdTargetExpressionList:[],
              campaignId:'',
              expressionType:'manual',
            },
            noKeyList:[],
            noGoodsType:'asinSameAs',
            noGoods:'',
            noGoodsList:'',
            batchInfo:{
              dailyBudgetUpdateReq:{
                amount:'',
                operateType:'',
                percent:''
              },
              date:'',
              ids:[],
              operateType:'',
              portfoliosId:'',
              profileId:''
            },
            addGroupInfo:{
              budget:{
                amount:'',
                endDate:'',
                policy:'',
                startDate:'',
              },
              name:'',
              state:'enabled'
            },
            portfolioId:'',
            adCampaignsTargetingTypeList:[],
            adCampaignsStrategyTypeList:[], //策略
            favoriteTypeList:[],
            portfoliosList:[],
            adCampaignsStateList:[],
            tress:[],
            total:'',
            adPortfoliosList:[],
            budgetOperateTypeList:[],
            operateTypeList:[],
            portfoliosBudgetTypeList:[],
            setStart:{
                disabledDate(time){
                    return time.getTime()<new Date().getTime()-8.64e7
                }
            },
            setEnd:{
                disabledDate(time){
                    return time.getTime()<new Date().getTime()-8.64e7
                }
            },
            radio:'选择日期',
            unit:'amount',
            value:'',
            inputList:'子ASIN',
            activeName:'first',
            goodsList:[],
            addedList:[],
            goodsTotal:'',
            archivednoGoodsIds:[],//归档否定商品
            archivedNoKeywordsIds:[],//归档否定关键字
            title:'',
            portfolioId:'',
            ListOfData: [],
            startTime: "",
            endTime: "",
            chartInatance: null,  //  保存chartsInstance 数据
            legendList: [
                {name:"曝光量",prop:'impressions'},
                {name:"点击量",prop:'clicks'},
                {name:"点击率",prop:'clickRate',persent:true},
                {name:"花费",prop:'cost',currency:true},
                {name:"CPC均价",prop:'clickAveCost',currency:true},
                {name:"订单数",prop:'attributedConversions14d'},
                {name:"CVR",prop:'cvr',persent:true},
                {name:"CPA",prop:'cpa',currency:true},
                {name:"销售额",prop:'attributedSales14d',currency:true},
                {name:"ACoS",prop:'acos',persent:true},
                {name:"RoAS",prop:'roas',persent:true},
            ],
            campaignName: "",
            groupRule:{
                name:[{required:true,message:'请输入组合名称',trigger:'blur'}],
            },
            budgetVisible: false,
            beforeBudget: "",
            formList: {
              money: "",
              goodsPromotion: "1"
            }
        }
    },
    computed:{
      addedNoKeyword(){
        let num = 0
        this.noLaunchInfo.neKeywordAddReqList.forEach(item=>{
          if(item.operation==1){
            num++
          }
        })
        return num
      },
      addedNoGoods(){
        let num = 0
        this.noLaunchInfo.neTargetAddReqList.forEach(item=>{
          if(item.yet==1){
            num++
          }
        })
        return num
      }
    },
    created(){
      this.getPageInit()
      this.tableHead=this.headList
      this.getGoods()
      this.getBatchInit()
    },
    mounted(){
    //   this.getNowTime()
    },
    watch:{
      $route(to){
        const info = this.$route.query;
        if(info.type=='getOne'){
          this.pageInfo.profileId = Number(info.profileId);
          this.pageInfo.keyword=info.value
          if(info.state){
              this.pageInfo.state=info.state
          }
          this.pageInfo.portfoliosId=''
          this.getListPage()
        }else{
          this.pageInfo.profileId = Number(info.profileId);
          this.pageInfo.keyword=''
          this.pageInfo.portfoliosId=info.portfoliosId?Number(info.portfoliosId):''
          this.pageInfo.state=info.state?info.state:''
          this.getListPage()
        }
      }
    },
    methods:{
      // 每日预算设置
      budgetSetting() {
        this.budgetVisible = true;
      },
      handleConfirm() {
        if(!this.formList.money&&this.formList.goodsPromotion=='1') {
          this.$message.error('请输入预算上限金额');
          return false; 
        }else {
          let params = {
            beforeBudget: this.beforeBudget,
            afterBudget: this.formList.goodsPromotion=='0'?'':parseInt(this.formList.money)
          }
          accountBudget(params, this.pageInfo.profileId)
          .then(res=> {
            console.log(res);
            if(res.data.code == 500) {
              this.$message.error(res.data.message); 
            }else {
              this.$message.success(res.data.message);
              this.budgetVisible = false;
            }
          })
        }
      },
      getNowTime() {
        var now = new Date();
        var year = now.getFullYear(); //得到年份
        var month = now.getMonth(); //得到月份
        var date = now.getDate(); //得到日期
        month = month + 1;
        month = month.toString().padStart(2, "0");
        date = date.toString().padStart(2, "0");
        this.addGroupInfo.budget.startDate = `${year}-${month}-${date}`;
        this.addActiveInfo.startDate=`${year}-${month}-${date}`;
      },
      budgetEnd(id){
        //   this.$refs.addGroupInfo.resetFields()
          this.$refs.addGroupInfo.clearValidate()

          if(id!='monthlyRecurring'){
            this.addGroupInfo.budget.endDate=''
            this.groupEnd='永不'
            this.addGroupInfo.budget.startDate=''
          }
          if(id=='dateRange') this.getNowTime()
      },
      changeGroup(row){
          this.title='修改广告组合'
          this.portfolioId=row.portfolioId
          this.loading=true
          groupUpdateInit(row.portfolioId).then(res=>{
            this.loading=false
            this.addGroupBox=true
            this.addGroupInfo.name=res.data.data.portfolios.name
            this.portfoliosBudgetTypeList=res.data.data.portfoliosBudgetTypeList
            this.addGroupInfo.budget.amount=res.data.data.portfolios.amount
            this.addGroupInfo.budget.endDate=res.data.data.portfolios.endDate
            this.addGroupInfo.budget.startDate=res.data.data.portfolios.startDate
            this.addGroupInfo.budget.policy=res.data.data.portfolios.policy
            if(this.addGroupInfo.budget.endDate!=''){
                this.groupEnd='开启'
            }else {
                this.groupEnd='永不'
            }
          }).catch((res)=>{
              this.loading=false
          })
      },
      changeDailyBudget(row,index){
          this.$prompt('修改每日预算', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue:row.dailyBudget,
          inputPattern:/^[0-9]+([.]{1}[0-9]+){0,1}$/,
          inputErrorMessage: '只能输入纯数字'
        }).then(({ value }) => {
           this.loading=true
            updateBid({bid:value,ids:[row.campaignId],type:'campaigns'},this.pageInfo.profileId).then(res=>{
              this.loading=false
              if(res.data.code==200){
                this.$message.success(res.data.message)
                // this.getListPage()
                this.tableData[index].dailyBudget=value
              }else {
                this.$message.error(res.data.message)
              }
            })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '取消输入'
                });       
            });
      },
      updateNeKeyword(){
           this.dialogLoading=true
           updateNeKeyword({type:'campaignNegativeKeywords',state:'archived',keywordIds:this.archivedNoKeywordsIds},this.pageInfo.profileId).then(res=>{
               this.dialogLoading=false
               if(res.data.code==200){
                   this.$message.success(res.data.message)
                   this.getListCampaignNegativeKeywords()
               }else{
                   this.$message.error(res.data.message)
               }
           }).catch(()=>{
               this.dialogLoading=false
               this.$message.error(res.data.message)
           })
      },
      archivedNeTarget(){
            this.dialogLoading=true
            archivedNeTarget({type:'campaignNegativeTargets',targetIds:this.archivednoGoodsIds},this.pageInfo.profileId).then(res=>{
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.getListNegativeTargetList()
                }else{
                    this.$message.error(res.data.message)
                }
                this.dialogLoading=false
            }).catch(()=>{
                this.dialogLoading=false
            })
      },
      selectNoKeywords(val){
            this.archivedNoKeywordsIds=val.map(item=>{
                return item.keywordId
            })
      },
      selectNoGoods(val){
            this.archivednoGoodsIds=val.map(item=>{
                return item.targetId
            })
      },
      archivedKeywordSelect(row,id){
            if(row.operation==1){
                return true
            }else {
                return false
            }
      },
      archivedSelect(row,ind){
            if(row.yet==1){
                return true
            }else {
                return false
            }
      },
      getCampaignList(id){
            const arr=[]
            this.campaignList.forEach(item=>{
                if(item.portfolioId==id){
                    arr.push(item)
                }
            })
            this.screenedCampaignList=arr
            this.pageInfo.campaignId=''
      },
      getPortfolioId(portfolioId,id){
            this.pageInfo.portfolioId=portfolioId
            this.pageInfo.campaignId=id
      },
      selectable(row,index){
            if(row.state=='archived'){
                return false
            }else{
                return true
            }
      },
      updateActiveBoxClose(){
            this.selectUpdateActive='setActive'
            this.updateActiveBox=false
            this.noLaunchInfo={
                neKeywordAddReqList:[],
                neTargetAddReqList:[]
            }
            this.addNegativeKeywordsInfo={//新增否定关键词信息
                adGroupId:'',
                amzAdKeywordForGroupAddReqList:[],
                campaignId:'',
                profileId:'',
            }
            this.addNeTargetsInfo={
                adGroupId:'',
                amzAdTargetExpressionList:[],
                campaignId:'',
                expressionType:'manual',
            }
            this.noKeyList=[]
            this.noGoodsList=[]
            this.addActiveClose()
      },
      delItem(index,name){    
            this.noLaunchInfo.neTargetAddReqList.splice(index,1)
            this.targetsBrandsList.forEach(item=>{
                if(this.noLaunchInfo.neTargetAddReqList.length==0){
                    item.operation=0
                }else{
                    this.noLaunchInfo.neTargetAddReqList.forEach(i=>{
                        if(item.name==i.name&&this.noGoodsType=='asinBrandSameAs'){
                            item.operation=0
                        }
                    })
                }
            })
            this.noGoodsList.forEach((item,ind)=>{
                if(item.name==name){
                    this.noGoodsList.splice(ind,1)
                }
            })
      },
      delAllnoGood(){
            if(this.noGoodsList.length==0){
                this.$message.warning('只能删除新增的，已存在的可点击归档以达到删除的目的')
            }else {
                this.noLaunchInfo.neTargetAddReqList.forEach((item,index)=>{
                    if(item.yet!=1){
                        this.noLaunchInfo.neTargetAddReqList.splice(index,1)
                    }
                })
                this.noGoodsList=[]
            }
      },
      delNegativeKeyword(index,keywordText){
            this.noLaunchInfo.neKeywordAddReqList.splice(index,1)
            this.noKeyList.forEach((item,ind)=>{
                if(item.keywordText==keywordText){
                    this.noKeyList.splice(ind,1)
                }
            })
      },
      delAllkeyword(){
            if(this.noKeyList.length==0){
                this.$message.warning('只能删除新增的，已存在的可点击归档以达到删除的目的')
            }else {
                this.noKeyList=[]
                this.noLaunchInfo.neKeywordAddReqList.forEach((item,index)=>{
                    if(item.operation!=1){
                        this.noLaunchInfo.neKeywordAddReqList.splice(index,1)
                    }
                })
            }
      },
      // 回显
      getListNegativeTargetList(){
            listNegativeTarget({campaignId:this.addNeTargetsInfo.campaignId}).then(res=>{
                if(res.data.code==200){
                    this.noLaunchInfo.neTargetAddReqList=res.data.data.map(item=>{
                        item.yet=1
                        return item
                    })
                }
            })
      },
      addNeTargets(){
            this.addNeTargetsInfo.amzAdTargetExpressionList=this.noGoodsList
            if(this.noGoodsList.length==0) return this.$message.warning('请添加否定商品')
            this.buttonLoading=true
            addNeTargets(this.addNeTargetsInfo,this.pageInfo.profileId).then(res=>{
                this.buttonLoading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.getListNegativeTargetList()
                }else{
                    this.$message.error(res.data.message)
                }
            })
      },
      toTargetsBrandsList(row,index){
            if(this.noGoods=='' && this.noGoodsType=='asinSameAs'){
                this.$message.warning('请输入内容')
                return ;
            }
            if(this.noGoods=='' && this.noGoodsType=='asinBrandSameAs'){
                this.noLaunchInfo.neTargetAddReqList.push({name:row.name,type:this.noGoodsType,value:row.id})
                this.noGoodsList.push({name:row.name,type:this.noGoodsType,value:row.id})
                this.targetsBrandsList[index].operation=1
            }else{
                // this.noLaunchInfo.neTargetAddReqList.push({name:this.noGoodsInput,type:this.noGoodsType,value:this.noGoodsInput})
                // this.noGoodsInput=''
                this.addNoGoods()
            }
      },
      addNoGoods(){
            if(!this.noGoods){
                this.$message.warning('请填写ASIN')
                return
            }
            const reg = /^B[0-9]{1}[0-9]{1}\w{7}$/
            const list=this.noGoods.split(/[(\r\n)\r\n]+/)
            const arr = []
            let num = 0
            list.forEach(item=>{
                if(!reg.test(this.noGoods)) num++
            })
            if(num>0){
                this.$message.warning(`有${num}个ASIN格式有误`)
                return
            }
            list.forEach(item=>{
                const flag = this.noLaunchInfo.neTargetAddReqList.some(i=>{
                    return item==i.value&&i.type=='asinSameAs'
                })
                if(!flag){
                    arr.push({name:item,value:item,type:'asinSameAs'})
                }
            })
            this.noGoodsList=[...this.noGoodsList,...arr]
            this.noGoods=''
            this.noLaunchInfo.neTargetAddReqList=[...this.noLaunchInfo.neTargetAddReqList,...arr]
      },
      addNegativeKeyword(){
            this.addNegativeKeywordsInfo.amzAdKeywordForGroupAddReqList=this.noKeyList
            console.log(this.addNegativeKeywordsInfo);
            if(this.addNegativeKeywordsInfo.amzAdKeywordForGroupAddReqList.length==0) return this.$message.warning('请添加关键词')
            this.buttonLoading=true
            addNegativeKeyword(this.addNegativeKeywordsInfo,this.pageInfo.profileId).then(res=>{
                this.buttonLoading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.noKeyList=[]
                    this.getListCampaignNegativeKeywords()
                }else {
                    this.$message.error(res.data.message)
                }
            }).catch(()=>{
                this.buttonLoading=false
            })
      },
      getListCampaignNegativeKeywords(){
            listCampaignNegativeKeywords({campaignId:this.addActiveInfo.campaignId}).then(res=>{
                if(res.data.code==200){
                    // 未封装组件   与添加广告组使用同一个变量  写代码前缺乏思考(￣▽￣)~*
                    this.noLaunchInfo.neKeywordAddReqList=res.data.data.map(item=>{
                        item.operation=1
                        return item
                    })
                }else{
                    this.$message.error(res.data.message)
                }
            })
      },
      addNoKey(){
            if(!this.noKeywords){
                this.$message.warning('请填写关键词')
                return
            }
            const arr = []
            const list=this.noKeywords.split(/[(\r\n)\r\n]+/)
            list.forEach(item=>{
                const flag =  this.noLaunchInfo.neKeywordAddReqList.some(i=>{
                    return item==i.keywordText&&this.matchType==i.matchType;
                })
                if(!flag){
                    arr.push({keywordText:item,matchType:this.matchType})
                }
            })
            this.noKeyList=[...this.noKeyList,...arr]
            this.noKeywords=''
            this.noLaunchInfo.neKeywordAddReqList=[...this.noLaunchInfo.neKeywordAddReqList,...arr]
      },
      pdateActiveClose(){
            this.updateActiveBox=false
            this.addActiveInfo={
                bidding:{
                    strategy:'autoForSales',
                    adjustments:[]
                },
                campaignType:'sponsoredProducts',
                dailyBudget:'',
                name:'',
                portfolioId:'',
                premiumBidAdjustment:'',
                startDate:'',
                endDate:'',
                state:'enabled',
                tags:{
                    accountManager:'',
                    ponumber:'',
                },
                targetingType:'auto'
            }
      },
      updateActive(id,campaignId,type){//更改广告互动  初始化
            this.loading=true
            updateInit(id).then(res=>{
                if(res.data.code==200){
                    this.updateActiveBox=true
                    this.loading=false
                    this.addActiveInfo={
                        bidding:{
                            strategy:res.data.data.strategy,
                            adjustments:[]
                        },
                        campaignId:res.data.data.campaignId,
                        campaignType:res.data.data.campaignType,
                        dailyBudget:res.data.data.dailyBudget,
                        name:res.data.data.name,
                        portfolioId:res.data.data.portfolioId,
                        // premiumBidAdjustment:Boolean(res.data.data.premiumBidAdjustment),======================暂时不用管
                        startDate:res.data.data.startDate,
                        endDate:res.data.data.endDate,
                        state:res.data.data.state,
                        tags:{
                            accountManager:this.$store.state.user.userAccount,
                            ponumber:this.$store.state.user.userId,
                        },
                        targetingType:res.data.data.targetingType
                    }
                    this.placementProductPage=res.data.data.placementProductPagePercent
                    this.placementTop=res.data.data.placementTopPercent

                    this.addNegativeKeywordsInfo.campaignId=campaignId
                    this.addNegativeKeywordsInfo.profileId=this.pageInfo.profileId

                    this.addNeTargetsInfo.campaignId=campaignId
                    this.addNeTargetsInfo.profileId=this.pageInfo.profileId
                    this.type=type
                }
            }).then(()=>{
                this.getListNegativeTargetList()
                this.getListCampaignNegativeKeywords()
            })
            
      },
      update(){
          this.$refs.addActiveInfo.validate(valid=>{
              if(!valid) return
                this.buttonLoading=true
            if(this.placementTop!==''&&this.placementTop!=0){
                this.addActiveInfo.bidding.adjustments.push({percentage:this.placementTop,predicate:'placementTop'})
            }
            if(this.placementProductPage!==''&&this.placementProductPage!=0){
                this.addActiveInfo.bidding.adjustments.push({percentage:this.placementProductPage,predicate:'placementProductPage'})
            }
            this.addActiveInfo.endDate=this.addActiveInfo.endDate==null?'':this.addActiveInfo.endDate.replace(/-/g,'')
            this.addActiveInfo.startDate=this.addActiveInfo.startDate==null?'':this.addActiveInfo.startDate.replace(/-/g,'')
            // if(!this.placementTop&&!this.placementProductPage){
            //     this.addActiveInfo.premiumBidAdjustment=false
            // }else {
            //     this.addActiveInfo.premiumBidAdjustment=true
            // }
            this.addActiveInfo.dailyBudget=Digits(this.addActiveInfo.dailyBudget,2)
            AmzAdCampaigns(this.addActiveInfo,this.pageInfo.profileId).then(res=>{
                this.buttonLoading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.updateActiveBoxClose()
                }else { 
                    this.$message.error(res.data.message)
                    this.addActiveInfo.bidding.adjustments=[]
                }
            }).catch(()=>{
                this.buttonLoading=false
            })

          })
      },
      addActiveClose(){
            this.addActive=false
            this.addActiveInfo={
                bidding:{
                    strategy:'autoForSales',
                    adjustments:[]
                },
                campaignType:'sponsoredProducts',
                dailyBudget:'',
                name:'',
                portfolioId:'',
                premiumBidAdjustment:'',
                startDate:'',
                endDate:'',
                state:'enabled',
                tags:{
                    accountManager:'',
                    ponumber:'',
                },
                targetingType:'auto'
            }
      },
      addGroupBoxClose(){
            // this.$refs.addGroupInfo.resetFields()
            this.$refs.addGroupInfo.clearValidate()
            this.addGroupBox=false
            this.addGroupInfo={
                budget:{
                    amount:'',
                    endDate:'',
                    policy:'',
                    startDate:'',
                },
                name:'',
                state:'enabled'
            }
      },
      selectable(row,index){
            if(row.state=='archived'){
                return false
            }else{
                return true
            }
      },
      focusChange(id,status){
            this.loading=true
            if(status==0){
                focusOn(id).then(res=>{
                    this.loading=false
                    if(res.data.code==200){
                        this.getListPage()
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }
            if(status==1){
                focusOff(id).then(res=>{
                    this.loading=false
                    if(res.data.code==200){
                        this.getListPage()
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            }
      },
      toGoods(item){
            item.operation=0
      },
      toAdded(item){
            item.operation=1
            this.addedList.push(item)
      },
      atOnce(){
            this.meanwhile=!this.meanwhile
            if(this.meanwhile=true){
                this.getGoods()
            }
      },
      getGoods(){
          getGoodsList().then(res=>{
            this.goodsList=res.data.values.map(item=>{
                item.operation=0
                return item
            })
            this.goodsTotal=res.data.pageInfo.total
          })
      },
      changeStatus(id,status,index){
           this.batchInfo.ids=[id]
           if(status=='paused'){
               this.batchInfo.operateType=2
           }else if(status=='enabled'){
               this.batchInfo.operateType=3
           }
           this.batchInfo.profileId=this.pageInfo.profileId
           this.loading=true
           batchUpdate(this.batchInfo).then(res=>{
               this.loading=false
               if(res.data.code==200){
                   this.$message.success(res.data.message)
                   this.setBudgetBox=false
                    //    this.getListPage()
                    this.tableData[index].state=status
                   this.batchInfo.date=''
               }else{
                   this.$message.warning(res.data.message)
                   if(status=='paused'){
                        this.tableData[index].state='enabled'
                    }else{
                        this.tableData[index].state='paused'
                    }
               }
           })
      },
      addADSgroup(){

          this.$refs.addGroupInfo.validate(valid=>{
                if(!valid) return
                if(!this.addGroupInfo.budget.amount&&this.addGroupInfo.budget.policy!=''){
                    this.$message.error('请输入预算')
                    return
                }
                if(this.addGroupInfo.budget.policy=='dateRange'&&new Date(this.addGroupInfo.budget.startDate).getTime()>new Date(this.addGroupInfo.budget.endDate).getTime()) return this.$message.error('开始日期不能大于结束日期')
                if(this.groupEnd=='永不'&&this.addGroupInfo.budget.policy!='dateRange') this.addGroupInfo.budget.endDate=''
                this.addGroupInfo.budget.amount=this.addGroupInfo.budget.policy!=''?Digits(this.addGroupInfo.budget.amount,2):''
                if(this.title=='新增广告组合'){
                    this.buttonLoading=true
                    addgroup([this.addGroupInfo],this.pageInfo.profileId).then(res=>{
                        this.buttonLoading=false
                        if(res.data.code==200){
                            this.$message.success(res.data.message)
                            this.addGroupBoxClose()
                            this.getListPage()
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(()=>{
                        this.buttonLoading=false
                    })
                }
                if(this.title=='修改广告组合'){
                    this.buttonLoading=true
                    amzAdPortfolios({budget:this.addGroupInfo.budget,name:this.addGroupInfo.name,portfolioId:this.portfolioId},this.pageInfo.profileId).then(res=>{
                        this.buttonLoading=false
                        if(res.data.code==200){
                            this.$message.success(res.data.message)
                            this.addGroupBoxClose()
                            this.getListPage()
                        }else{
                            this.$message.error(res.data.message)
                        }
                    }).catch(()=>{
                        this.buttonLoading=false
                    })
                } 

          })
           
      },
      addGroup(){
          this.title='新增广告组合'
          this.loading=true
          addGroupInit().then(res=>{
              this.getNowTime()
              this.loading=false
              this.addGroupBox=true
              this.portfoliosBudgetTypeList=res.data.data.portfoliosBudgetTypeList
          })
      },
      getBudget(info){
            if(info=='cancel'){
                this.batchInfo.dailyBudgetUpdateReq={
                    amount:'',
                    operateType:'',
                    percent:''
                },
                this.setBudgetBox=false
                this.getListPage()
            }
            if(this.unit=='amount'){
                this.batchInfo.dailyBudgetUpdateReq.amount=this.value
            }
            if(this.unit=='percent'){
                this.batchInfo.dailyBudgetUpdateReq.percent=this.value
            }
            this.loading=true
            batchUpdate(this.batchInfo).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.setBudgetBox=false
                    this.getListPage()
                    this.batchInfo.date=''
                }else{
                    this.$message.warning(res.data.message)
                }
            })
      },
      selectBudget(val){
            if(val.includes('百分比')){
                this.unit='percent'
            }else {
                this.unit='amount'
            }
      },
      setEndTime(info){
            if(info=='cancel'){
                this.batchInfo.date=''
                this.setEndBox=false
                this.$message.info('取消操作')
                this.getListPage()
                return ;
            }
            if(this.radio=='无结束日期'){
                this.batchInfo.date=null
            }
            // ===========================================================================无结束日期
            this.loading=true
            batchUpdate(this.batchInfo).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.setEndBox=false
                    this.getListPage()
                    this.batchInfo.date=''
                }else{
                    this.$message.warning(res.data.message)
                }
            })
      },
      selectEnd(val){
            if(new Date(val).getTime()<new Date(this.maxStart).getTime()){
                this.$message.warning(`结束时间不得小于${this.maxStart}`)
            }
      },
      setBegin(info){
            if(info=='cancel'){
                this.batchInfo.date=''
                this.setBeginBox=false
                this.$message.info('取消操作')
                this.getListPage()
                return ;
            }
            this.loading=true
            batchUpdate(this.batchInfo).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.setBeginBox=false
                    this.getListPage()
                    this.batchInfo.date=''
                }else{
                    this.$message.warning(res.data.message)
                }
            })
      },
      addChange(info){
            if(info=='cancel'){
                this.batchInfo.portfoliosId=''
                this.addChangeBox=false
                this.$message.info('取消操作')
                return ;
            }
            this.loading=true
            batchUpdate(this.batchInfo).then(res=>{
                this.loading=false
                if(res.data.code==200){
                    this.$message.success(res.data.message)
                    this.addChangeBox=false
                    this.batchInfo.portfoliosId=''
                    this.getListPage()
                }else {
                    this.$message.warning(res.data.message)
                }
            })
      },
      batchHandle(id,value){
            if(this.batchInfo.ids.length==0){
                this.$message.warning('请选择具体内容')
                return
            }
            this.batchInfo.operateType=id
            this.batchInfo.profileId=this.pageInfo.profileId
            if(id==2||id==3||id==4||id==8||id==9){
                this.$confirm(`将要批量进行‘${value}’操作, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading=true
                    batchUpdate(this.batchInfo).then(res=>{
                        this.loading=false
                        if(res.data.code==200){
                            this.$message.success(res.data.message)
                            this.getListPage()
                        }else {
                            this.$message.warning(res.data.message)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });          
                });
            }
            if(id==7) this.addChangeBox=true
            if(id==5) this.setBeginBox=true
            if(id==6) {
                this.setEndBox=true
                this.maxStart=fmtDate(this.maxStart)
            }
            if(id==1){
                this.setBudgetBox=true
            }
            
      },
      handleSelectionChange(val){
            const arr = []
            let max=''
            val.forEach(item=>{
                arr.push(item.id)
                const time=new Date(item.startDate).getTime()
                if(time>max) max=time
            })
            this.batchInfo.ids=arr
            this.maxStart=max
      },
      getBatchInit(){
            batchInit().then(res=>{
                this.operateTypeList=res.data.data.operateTypeList
                this.budgetOperateTypeList=res.data.data.budgetOperateTypeList
            })
      },
      Start(val){
          console.log(val);
            if(!this.addActiveInfo.endDate) return
            const endDate=new Date(this.addActiveInfo.endDate).getTime()
            const startDate=new Date(this.addActiveInfo.startDate).getTime()
            if(endDate<startDate){
                this.$message.warning('开始日期不能大于结束日期')
                this.addActiveInfo.endDate=''
            }
      },
      End(){
            if(!this.addActiveInfo.startDate) return
            const endDate=new Date(this.addActiveInfo.endDate).getTime()
            const startDate=new Date(this.addActiveInfo.startDate).getTime()
            if(endDate!=''&&endDate<startDate){
                this.$message.warning('结束日期不能小于开始日期')
                this.addActiveInfo.endDate=''
            }
      },
      sureActive(){
          this.$refs.addActiveInfo.validate(valid=>{
              if(!valid) return

              if(this.placementTop!==''&&this.placementTop!=0){
                this.addActiveInfo.bidding.adjustments.push({percentage:this.placementTop,predicate:'placementTop'})
            }
            if(this.placementProductPage!==''&&this.placementProductPage!=0){
                this.addActiveInfo.bidding.adjustments.push({percentage:this.placementProductPage,predicate:'placementProductPage'})
            }
            this.addActiveInfo.endDate=this.addActiveInfo.endDate==null?'':this.addActiveInfo.endDate.replace(/-/g,'')
            this.addActiveInfo.startDate=this.addActiveInfo.startDate==null?'':this.addActiveInfo.startDate.replace(/-/g,'')
            this.addActiveInfo.tags.accountManager=this.$store.state.user.userAccount
            this.addActiveInfo.tags.ponumber=this.$store.state.user.userId
            if(!this.placementTop&&!this.placementProductPage){
                this.addActiveInfo.premiumBidAdjustment=false
            }else {
                this.addActiveInfo.premiumBidAdjustment=true
            }

            this.addActiveInfo.dailyBudget=Digits(this.addActiveInfo.dailyBudget,2)
            this.buttonLoading=true
            addActive(this.addActiveInfo,this.pageInfo.profileId).then(res=>{
                this.buttonLoading=false

                if(res.data.code==200){
                    this.addActive=false
                    this.addActiveClose()
                    this.$message.success(res.data.message)
                    this.getListPage()
                    this.$store.commit('setNum')
                }else{
                    this.$message.error(res.data.message)
                    this.addActiveInfo.bidding.adjustments=[]
                }
            }).catch(()=>{
                this.buttonLoading=false
                this.addActiveInfo.bidding.adjustments=[]
            })
          })
      },
      addADactive(){

            this.loading=true
            addInit(this.pageInfo.profileId).then(res=>{
                this.getNowTime()
                this.loading=false
                this.addActive=true
                this.adPortfoliosList=res.data.data.adPortfoliosList
            })
      },
      getTimeSection(val){
            this.pageInfo.beginTime=val.value[0]
            this.pageInfo.endTime=val.value[1]
      },
      getID(id,currency){
            this.pageInfo={
                current:'',
                profileId:'',
                portfoliosId:'',
                state:'',
                strategy:'',
                targetingType:'',
                isFavorite:'',
                keyword:'',
                beginTime:this.pageInfo.beginTime,
                endTime:this.pageInfo.endTime,
                sortColumn:'',
                sort:'',
                pageSize:''
            }
            this.pageInfo.profileId=id
            this.currency=currency
            this.getPageInit('currentPage')
      },
      handleSizeChange(val){
          this.pageInfo.pageSize=val
          this.getListPage()
      },
      handleCurrentChange(val){
          this.pageInfo.current=val
          this.getListPage()
      },
      setHead(val){
          this.tableHead=val
      },
      getPageInit(val){
            this.loading=true
            pageInit(this.pageInfo.profileId||this.$store.state.profileId?this.pageInfo.profileId||this.$store.state.profileId:0).then(res=>{
                this.loading=false
                this.adCampaignsTargetingTypeList=res.data.data.adCampaignsTargetingTypeList
                this.adCampaignsStrategyTypeList=res.data.data.adCampaignsStrategyTypeList
                this.favoriteTypeList=res.data.data.favoriteTypeList
                this.portfoliosList=res.data.data.portfoliosList
                this.adCampaignsStateList=res.data.data.adCampaignsStateList
                this.tress=res.data.data.tress
                this.formList.money = res.data.data.dailyBudget?parseInt(res.data.data.dailyBudget):"";
                this.beforeBudget = res.data.data.dailyBudget?parseInt(res.data.data.dailyBudget):"";
                if(this.pageInfo.profileId==''){
                     this.tress.forEach(item=>{
                        item.marketPlaceTree.forEach(i=>{
                            if(i.isSelected==1){
                                this.pageInfo.profileId=i.profileId
                                this.pageInfo.current=1
                                this.currency=i.currency
                            }
                        })
                    })
                }
                if(val=='currentPage'){
                    this.getListPage()
                    return
                }
                let info = this.$route.query;
                if(JSON.stringify(info)!='{}'){
                    if(info.type=='getOne'){
                        this.pageInfo.profileId = Number(info.profileId);
                        this.pageInfo.keyword=info.value
                        if(info.state){
                            this.pageInfo.state=info.state
                        }
                        this.pageInfo.portfoliosId=''
                        this.getListPage()
                    }else{
                        this.pageInfo.profileId = Number(info.profileId);
                        this.pageInfo.keyword=''
                        this.pageInfo.portfoliosId=info.portfoliosId?Number(info.portfoliosId):''
                        this.pageInfo.state=info.state?info.state:''
                        this.getListPage()
                    }
                }else{
                    this.getListPage()
                }
            }).then(()=>{
                
            })
      },
      getListPage(){
        this.$store.commit('setProfileId',this.pageInfo.profileId)
        this.loading=true
        listPage(this.pageInfo).then(res=>{
            this.loading=false
            this.tableData=res.data.values
            this.total=res.data.pageInfo.total
        })
      },
      find(){
          this.pageInfo.current=1
          this.getListPage()
      },
      sortTable(column){
          if(column.order=='ascending'){
              this.pageInfo.sort='asc'
          }else if(column.order=='descending'){
              this.pageInfo.sort='desc'
          }else if(column.order==null){
              this.pageInfo.sort=''
              this.pageInfo.sortColumn=''
              this.getListPage()
              return
          }
          this.pageInfo.sortColumn=column.prop
          this.getListPage()
      },
      // 时间选择
      changeTime(val) {
        this.startTime = val.value[0];
        this.endTime = val.value[1];
      }, 
      getChart(row){
          this.campaignName = row.name;
          this.$store.commit('setAdvName',row.campaignId)
          this.analysisClick()
      },
      // 历史趋势分析 
      analysisClick(val) {
        let params = {
            profileId: this.pageInfo.profileId,
            startDate: val?val.value[0]:'',
            endDate: val?val.value[1]:'',
            campaignId: this.$store.state.advName
        }
        listByCampaign(params)
         .then(res=> {
           if(res.data.code == 200) {
             this.ListOfData = res.data.data;
           }else if(res.data.code == 500) {
             this.$message.error(res.message.error);
           }
         })
        this.drawer = true;
      },
    }
}
</script>

<style scoped lang='scss'>
// ::v-deep .el-switch__core{
//     width:30px!important;
//     height:15px;
//     /*color:#409EFF;*/
//   }
//   /*设置圆*/
// ::v-deep .el-switch__core::after{
//     width:13px;
//     height:13px;
//     margin-top:-1px;
//     margin-bottom: 2px;
// }
.add{
    width: 80%;
    margin: 0 auto;
}
.Box{
    display: flex;
    margin: 20px 40px;
    >div{
        overflow: auto;
        flex: 1;
        border: 1px solid #F0F0F0;
        padding: 10px;
        position: relative;
        >.all{
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}
.item{
    height: 85px;
    border-bottom:1px solid #F0F0F0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    .pic{
        width: 70px;
        height: 70px;
        background:grey;
    }
    .SKU{
        height: 50px;
        width: 170px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .state{
        display: inline-block;
        width: 24px;
        height: 16px;
        vertical-align: middle;
        margin-top: -4px;
    }
    .realizable{
        width: 120px;
        height: 50px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .price{
        width: 150px;
        height: 60px;
        p{
            line-height: 60px;
        }
    }
}
.words{
    border-left: 1px solid #F0F0F0;
    border-right: 1px solid #F0F0F0;
    margin: 0 40px;
    .keyword{
        display: flex;
        >div{
            flex: 1;
            margin: 10px;
            padding-right:15px;
        }
        >div:nth-of-type(1){
            border-right: 1px solid #F0F0F0;
        }
    }
}
::v-deep .el-collapse-item__content{
  padding-bottom:0 ;
}
.el-icon-star-off{
  color:grey;
}
.el-icon-star-on{
  color: #F7BA2A;
  font-size: 20px !important;
}
::v-deep .el-select-group__title{
  font-size:12px;
  margin-left: -9px;
  transform: scale(0.86);
}
::v-deep .el-select-dropdown__item{
  margin-left: 14px;
}
.keywordClass {
  margin-top: 20px;
  margin-bottom: 30px;
  left: 150px;
  width: 2650px;
  height: 650px;
  border-radius: 20px;
}
</style>